import React, { useEffect, useState } from "react";
import FormRedeemCodeV2_static from "../components/FormRedeemCodeV2/FormRedeemCodeV2_static";
import Meta from "../components/Meta/Meta";

const RedeemCoupon = (props) => {
  const { data } = props.pageContext;
  const { labels } = data;
  //const id = beekeeper.id
  const [coupon, setCoupon] = useState("");
  const [honey_id, setHoney] = useState("");
  const [beekeeper_id, setBeekeeper] = useState("");
  const [hive_id, setHive] = useState("");

  // Get parameter from urls.
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const _coupon = urlParams.get("coupon");
    const _honey_id = urlParams.get("honey_id") ?? 5; // Millefiori
    const _beekeeper_id = urlParams.get("beekeeper_id") ?? 2; // Riccardo
    const _hive_id = urlParams.get("hive_id") ?? 59; // Mughetto

    if(_coupon){
      setCoupon(_coupon);
    }
    if(_honey_id ){
      setHoney(parseInt(_honey_id));
    }
    if(_beekeeper_id){
      setBeekeeper(parseInt(_beekeeper_id));
    }
    if( _hive_id){
      setHive(parseInt(_hive_id));
    }
  }, []);

  return (
    <>  
      <Meta />
      <div style={{ height: 150 }}></div>
      <FormRedeemCodeV2_static />
      <div style={{ height: 150 }}></div>
    </>
  );
};

export default RedeemCoupon;
